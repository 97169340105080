<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useHttp from '@/comp-functions/useHttp'
import router from "@/router"
import { ref } from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@/libs/vee-validate'
import Swal from "sweetalert2";

export default {
  page: {
    title: "Add Vendor",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    Layout,
    PageHeader,
    ValidationProvider,
    ValidationObserver
  },
   data() {
    return {
      title: "Add Vendor",
      defaultNull: null
    };
  },
  setup () {

      const { $post } = useHttp()
      const masterRef = ref(null)

      const form = ref({
      name: '',
      address:'',
      contactName:'',
      contactPhone:'',
      vndCapacitydef:''
      })

      const validationForm = async () => {

          if(validationDetail()){
        const validated = await masterRef.value.validate()
        if(validated){

        let dataSubmit = {
        name : form.value.name,
        address : form.value.address,
        contactName : form.value.contactName,
        contactPhone : form.value.contactPhone,
        vndCapacitydef : form.value.vndCapacitydef
        }

      $post({
        url: 'master/vendor/vendors-add',
        data: dataSubmit
      })
      masterRef.value.reset();
      router.push({ name: 'appsmd-vendor-list' })
      masterRef.value.reset();
       }
     }
    }

 const validationDetail = ()=> {
     if(
       form.value <1 ||
       form.value === null
      ){
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "There is no details found",
          html: `You must add details`,
          showConfirmButton: true,
          timer: 2600,
          timerProgressBar: true
        });
        return false
      }else{
        return true
      }
    }

    return {
      form,
      validationForm,
      required,
      masterRef
    }
  }

};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
               <validation-observer ref="masterRef">
                <b-form @submit.prevent>
                 <div class="form-group mb-3">
                        <label>Vendor Name</label><br />
                        <validation-provider
                        #default="{ errors }"
                        name="name"
                        rules="required"
                        >
                        <b-form-input id="" name="" value="" v-model="form.name"></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>

                  <div class="form-group mb-3">
                        <label>Address</label><br />
                        <validation-provider
                        #default="{ errors }"
                        name="address"
                        rules="required"
                        >
                        <b-form-input id="" address="" value="" v-model="form.address"></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>

                  <div class="form-group mb-3">
                        <label>Contact Name</label><br />
                        <validation-provider
                        #default="{ errors }"
                        name="contact name"
                        rules="required"
                        >
                        <b-form-input id="" contactName="" value="" v-model="form.contactName"></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>


                  <div class="form-group mb-3">
                        <label>Contact Phone</label><br />
                        <validation-provider
                        #default="{ errors }"
                        name="contact phone"
                        rules="required"
                        >
                        <b-form-input id="" contactPhone="" value="" v-model="form.contactPhone"></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>
                  <div class="form-group mb-3">
                        <label>Load</label><br />
                        <validation-provider
                        #default="{ errors }"
                        name="load"
                        rules="required"
                        >
                        <b-form-input id="" vndCapacitydef="" value="" v-model="form.vndCapacitydef"></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>
               </b-form>
              </validation-observer>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12 text-center">
                <div class="button-items">
                   <b-button variant="primary" @click.prevent="validationForm">
                    <i class="ri-save-line align-middle ml-2"></i>
                    Submit
                  </b-button>
                  <b-button variant="danger" :to="{ name: 'appsmd-vendor-list'}">
                    <i class="ri-arrow-left-line align-middle mr-2"></i> Back
                  </b-button>
                </div>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>